import React, { SFC } from 'react';
import { Formik, Form } from 'formik';
import { useLoginMutation } from '@jetshop/core/hooks/useLoginMutation';
import validate from '@jetshop/ui/Auth/logInValidator';
import t from '@jetshop/intl';
import get from 'lodash.get';
import { useLoginRedirect } from '@jetshop/core/hooks/useLoginRedirect';
import { Redirect } from 'react-router';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

const LogInFormProvider: SFC<{
  children: (props: any) => React.ReactNode;
  redirect?: string;
  initialEmail: string;
}> = ({ children, redirect, initialEmail }) => {
  const { getRedirectUrl } = useLoginRedirect();
  const { loggedIn } = useAuth();
  const { loginMutation } = useLoginMutation();
  const { routes } = useShopConfig();
  redirect = redirect ? redirect : routes.myPages.path;
  return (
    <Formik
      validate={validate}
      initialValues={{
        email: initialEmail,
        password: ''
      }}
      onSubmit={(values, actions) => {
        loginMutation({
          variables: values
        }).then(
          () => {
            const returnUrlPromise = getRedirectUrl();
            if (returnUrlPromise) {
              returnUrlPromise.then(returnUrl => {
                if (returnUrl) {
                  window.location.href = returnUrl;
                } else {
                  actions.setSubmitting(false);
                }
              });
            } else {
              actions.setSubmitting(false);
            }
          },
          error => {
            actions.setSubmitting(false);
            actions.setErrors(error);
            actions.setStatus({
              globalError: t('Login failed. Please try again')
            });
          }
        );
      }}
      render={props => (
        <>
          {!props.isSubmitting && loggedIn && <Redirect to={redirect} />}
          <Form>
            {children({
              ...props,
              globalError: get(props, 'status.globalError')
            })}
          </Form>
        </>
      )}
    />
  );
};

LogInFormProvider.defaultProps = {
  initialEmail: ''
};

export default LogInFormProvider;
