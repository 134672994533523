import React, { useState } from 'react';
import { Formik } from 'formik';
import TrendButton from '../../ui/Button';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { CustomerStatusHeaderText } from './VoyadoLookup';
import Input from '../../Forms/Input';
import { useQuery } from 'react-apollo';
import { DynamicContentQuery } from '../../DynamicContentQuery.gql';
import emailMisspelled, { top100 } from 'email-misspelled';
import { StyledTrendButton } from './VoyadoLookup';
export const LookupFieldContainer = styled.section`
  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    button {
      width: 100%;
    }
  }

  dl {
    display: flex;
    flex-flow: row wrap;
  }
  dt {
    flex-basis: 50%;
    padding: 2px 4px;
    text-align: right;
    font-weight: 600;
    ::after {
      content: ':';
    }
  }
  dd {
    flex-basis: 50%;
    flex-grow: 1;
    margin: 0;
    padding: 2px 4px;
  }
`;

export const ExternalLookupField = ({ lookup, isExternalLookupPending }) => {
  const { data, loading } = useQuery(DynamicContentQuery, {
    variables: { id: 1967 }
  });
  const [corrected, setCorrected] = useState(null);

  const emailChecker = emailMisspelled({ maxMisspelled: 3, domains: top100 });
  const blurCatch = values => {
    const check = emailChecker(values['lookupKey']);
    if (check.length > 0) {
      setCorrected(check[0].corrected);
    }
  };
  return (
    <>
      <CustomerStatusHeaderText>
        {data?.category?.content && (
          <div
            className="signupContent"
            dangerouslySetInnerHTML={{ __html: data.category.content }}
          />
        )}
      </CustomerStatusHeaderText>
      <Formik
        onSubmit={values => lookup(values.lookupKey)}
        validateOnBlur={true}
        initialValues={{
          lookupKey: ''
        }}
        validate={blurCatch}
      >
        {({ handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          return (
            <LookupFieldContainer className="loginSection">
              <form onSubmit={handleSubmit}>
                <Input
                  label={t('Epost')}
                  name="lookupKey"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  validate={blurCatch}
                />
                {corrected && (
                  <ul className="suggest">
                    <li>
                      {t('Did you mean')}{' '}
                      <span
                        onClick={() => {
                          setFieldValue('lookupKey', corrected);
                          setCorrected(null);
                        }}
                      >
                        {corrected}
                      </span>
                      ?
                    </li>
                  </ul>
                )}
                <StyledTrendButton
                  data-testid="commit-lookup"
                  type="submit"
                  disabled={isExternalLookupPending}
                >
                  {t('Login')}
                </StyledTrendButton>
              </form>
            </LookupFieldContainer>
          );
        }}
      </Formik>
    </>
  );
};
