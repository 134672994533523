import LogInFormProvider from '../Auth/fakeCore/LoginFormProvider';
import t from 'format-message';
import React, { useState, useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { styled } from 'linaria/react';
import { cx, css } from 'linaria';
import { Link } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import MaxWidth from '../Layout/MaxWidth';
import TrendButton from '../ui/Button';
import { pageTitle } from '../ui/Headings';
import { activeSegment, smallSection, paddingBox } from './UI/Form';
import { ExternalLookup } from './Signup/PersonLookup/ExternalLookup';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Redirect } from 'react-router';
import { ReactComponent as HfLogo } from '../MyPages/imgs/hf_logos.svg';
import bgImg from '../../img/loginbg.jpg';
import { useHistory } from 'react-router-dom';

import { theme } from '../Theme';

export const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  background: ${theme.colors.accent};
  color: white;
  width: calc(100% - 2rem);
  margin: 0 1rem;
  &:disabled {
    color: #808080;
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.primary};
  text-align: right;
  font-size: 0.875em;

  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

export const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: center;
  margin: auto;
  background: rgba(255, 255, 255, 0.5);
  padding: 5rem 2rem 5rem 2rem !important;

  max-width: 1150px;
  section {
    background: rgba(0, 0, 0, 0.2);
  }
  svg {
    max-width: 20rem;
    margin-bottom: 2rem;
    .hf-2 {
      fill: black !important;
    }
  }
  ${theme.below.sm} {
    padding: 4rem 1rem !important;
  }
`;

const LoginBottom = styled('div')`
  button {
    margin-bottom: 20px;
  }
  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${theme.below.sm} {
      display: block;
      a {
        display: block;
        text-align: left;
      }
    }
  }
`;

const LoginWrapper = styled('div')`
  background: url(${bgImg});
  background-size: cover;
  background-position: center center;
  padding: 10rem 5rem;
  ${theme.below.sm} {
    padding: 4rem 0.5rem !important;
  }
  input {
    border: none;
    padding: 0.4rem;
    height: 3rem;
  }
`;

function LogInPage() {
  const [email, setEmail] = useState(null);
  const { selectedChannel } = useContext(ChannelContext);
  const history = useHistory();
  const { loggedIn } = useAuth();

  if (loggedIn) {
    history.push('/my-pages');
  }

  if (selectedChannel.name === 'EU') {
    return <Redirect to="/" />;
  }

  return (
    <LoginWrapper>
      <LogInPageMaxWidth>
        <HfLogo />
        {!email || typeof email === 'object' ? (
          <ExternalLookup setInitial={setEmail} />
        ) : (
          <LogInFormProvider initialEmail={email} redirect="my-pages">
            {({
              globalError,
              isSubmitting,
              isValid,
              values,
              setValues,
              ...rest
            }) => (
              <section className={cx(smallSection, activeSegment, paddingBox)}>
                <header>
                  <h1 className={pageTitle}>{t('Login')}</h1>
                </header>

                <Input
                  initialValue={email}
                  value={email}
                  type="email"
                  name="email"
                  label={t('E-mail address')}
                />
                <Input type="password" name="password" label={t('Password')} />

                {globalError && (
                  <GlobalError style={{ marginBottom: '2em' }}>
                    {globalError}
                  </GlobalError>
                )}

                <LoginBottom>
                  <StyledTrendButton
                    type="submit"
                    loading={isSubmitting ? true : false}
                    loadingText={t('Hold on a moment...')}
                  >
                    {t('Log in')}
                  </StyledTrendButton>

                  <div>
                    <TextLink to="/forgot-password">
                      {t('Forgot password?')}
                    </TextLink>
                  </div>
                </LoginBottom>
              </section>
            )}
          </LogInFormProvider>
        )}
      </LogInPageMaxWidth>
    </LoginWrapper>
  );
}

export default LogInPage;
