import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import { useVoyadoLookup } from '@jetshop/flight-voyado';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Redirect } from 'react-router';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';

import { GlobalError } from '../../Forms/GlobalError';
import Input from '../../Forms/Input';
import MaxWidth from '../../Layout/MaxWidth';
import TrendButton from '../../ui/Button';
import { activeSegment, smallSection } from '../UI/Form';
import { theme } from '../../Theme';

import { ExternalLookupField, LookupFieldContainer } from './VoyadoLookupField';
import { Formik } from 'formik';
import { ReactComponent as HfLogo } from '../../MyPages/imgs/hf_logos.svg';
import bgImg from '../../../img/loginbg.jpg';

export const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  min-width: 155px;
  background: ${theme.colors.accent};
  color: white;
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 1rem;
  > * {
    flex: 1 1 calc(50% - 1em);
  }
  a {
    color: #000;
  }
  .forgot-password {
    background-color: #fff;
    opacity: 0.8;
    padding: 0.5rem 1rem;
    border-radius: 3px;
  }
`;

const TextLink = styled(Link)`
  text-decoration: none;

  text-align: right;
  font-size: 0.875em;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

const LoginWrapper = styled('div')`
  background: url(${bgImg});
  background-size: cover;
  background-position: center center;
  padding: 10rem 5rem;
  ${theme.below.sm} {
    padding: 4rem 0.5rem !important;
  }
  input {
    border: none;
    padding: 0.4rem;
    height: 3rem;
  }
`;

export const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: flex-start;
  margin: auto;
  background: rgba(200, 200, 200, 0.2);
  box-shadow: rgba(17, 17, 26, 0.05) 0px 4px 16px,
    rgba(17, 17, 26, 0.05) 0px 8px 32px;
  padding: 5rem 2rem 5rem 2rem !important;
  max-width: 1150px;
  backdrop-filter: blur(2px);
  section.loginSection {
    padding: 0 3rem;
    width: 100%;
    max-width: 30rem;
  }
  & > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  svg {
    max-width: 20rem;
    margin-bottom: 2rem;
    .hf-2 {
      fill: black !important;
    }
  }
  ${theme.below.sm} {
    padding: 4rem 1rem !important;
  }
  .suggest {
    margin-top: -1.5rem;
    padding: 0.5rem;
    margin-bottom: 1.5rem;
    font-size: 13px;
    span {
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;

const LoginPageHeader = styled('header')`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
`;

export const CustomerStatusHeaderText = styled('h4')`
  margin-bottom: 0.5rem;
  font-style: normal;
  font-weight: 400;
`;

const country = {
  NO: t('Norge'),
  SE: t('Sverige')
};

const LookupWrapper = ({ children, className }) => (
  <LoginWrapper>
    <LogInPageMaxWidth className={className}>
      <HfLogo />
      {children}
    </LogInPageMaxWidth>
  </LoginWrapper>
);

function VoyadoLookup() {
  const { ...voyado } = useVoyadoLookup({
    lookupOptions: {
      personLookupConfigured: true
    }
  });

  const { routes } = useShopConfig();
  const { selectedChannel } = useContext(ChannelContext);
  const { logIn, loggedIn } = useAuth();

  if (loggedIn) {
    return <Redirect to={routes.myPages.path} />;
  }

  if (voyado.isAdditionalDataRequired) {
    return (
      <Redirect
        to={{
          pathname: routes.signup.path,
          state: {
            customer: {
              ...voyado.customer
            },
            status: {
              isAdditionalDataRequired: voyado.isAdditionalDataRequired
            }
          }
        }}
      />
    );
  }

  if (voyado.isPersonLookupIdle || voyado.isPersonLookupPending) {
    return (
      <LookupWrapper className="max-width">
        <LookupFieldContainer>
          <CustomerStatusHeaderText>
            {t('Fyll i ditt personnummer')}
          </CustomerStatusHeaderText>
          <Formik
            initialValues={{ lookupKey: '' }}
            onSubmit={values => {
              voyado.personLookup(values.lookupKey);
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Input
                  type="text"
                  label="PID"
                  name="lookupKey"
                  id="lookupKey"
                />
                <StyledTrendButton
                  type="submit"
                  disabled={voyado.isPersonLookupPending}
                  loading={voyado.isPersonLookupPending}
                  loadingText={t('Hold on a moment...')}
                >
                  {t('Hämta mina uppgifter')}
                </StyledTrendButton>
              </form>
            )}
          </Formik>
        </LookupFieldContainer>
      </LookupWrapper>
    );
  }

  if (voyado.isNonExistingCustomer || voyado.isPersonLookupFailed) {
    return (
      <Redirect
        to={{
          pathname: routes.signup.path,
          state: {
            customer: { ...voyado?.customer, externalId: '' },
            status: {
              isNonExistingCustomer: voyado.isNonExistingCustomer
            }
          }
        }}
      />
    );
  }

  if (voyado.isCountryNotValid) {
    return (
      <LookupWrapper>
        <CustomerStatusHeaderText data-testid="country-not-valid-status">
          {t.rich(
            "You're trying to activate an account with country { countryCode } that doesn't exist in { market }. Try selecting a country that matches { countryCode } from our country selector.",
            {
              countryCode: country[voyado?.customer?.countryCode],
              market: selectedChannel.url
            }
          )}
        </CustomerStatusHeaderText>
      </LookupWrapper>
    );
  }

  if (voyado.isPersonLookupSuccess) {
    return (
      <Redirect
        to={{
          pathname: routes.signup.path,
          state: {
            customer: { ...voyado?.customer, externalId: '' },
            status: {
              isPersonLookupSuccess: voyado.isPersonLookupSuccess
            }
          }
        }}
      />
    );
  }

  if (voyado.isInActivation) {
    const { isActivationRequired, isActivationPending, isActivationSuccess, isActivationFailed } =
      voyado;
    return (
      <LookupWrapper>
        <CustomerStatusHeaderText
          data-testid={isActivationSuccess && 'successfully-activated'}
        >
          {isActivationRequired &&
            t(
              'You exist as an member, click to activate your account. We will send you an email with a link on how to set your password.'
            )}
          {isActivationSuccess &&
            t('We have successfully activated your account.')}
          {isActivationPending && t('Activating account')}
          {isActivationFailed && t('Please contact support')}
        </CustomerStatusHeaderText>
        <StyledTrendButton
          data-testid="commit-activation"
          disabled={isActivationPending}
          loading={isActivationPending}
          onClick={() => {
            if (isActivationRequired) {
              voyado.activate();
            } else {
              logIn(voyado.customer.token);
            }
          }}
        >
          {isActivationRequired && t('Activate')}
          {isActivationPending && t('Activating')}
          {isActivationSuccess && t('Login')}
          {isActivationFailed && t('Activation failed')}
        </StyledTrendButton>
      </LookupWrapper>
    );
  }

  if (voyado.isPreExistingCustomer) {
    return (
      <LookupWrapper>
        <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
        <LogInFormProvider
          redirect="my-pages"
          initialEmail={voyado?.customer?.emailAddress?.masked}
        >
          {({ globalError, isSubmitting, isValid }) => (
            <section
              className={cx(smallSection, activeSegment, 'loginSection')}
            >
              <Input type="email" name="email" label={t('E-mail address')} />
              <Input type="password" name="password" label={t('Password')} />

              {globalError && (
                <GlobalError style={{ marginBottom: '2em' }}>
                  {globalError}
                </GlobalError>
              )}

              <section className={actionWrapper}>
                <StyledTrendButton
                  type="submit"
                  data-testid="login-button"
                  disabled={!isValid}
                  loading={isSubmitting}
                  loadingText={t('Hold on a moment...')}
                >
                  {t('Log in')}
                </StyledTrendButton>
                <TextLink to={routes.forgotPassword.path} className='forgot-password'>
                  {t('Forgot password?')}
                </TextLink>
              </section>
            </section>
          )}
        </LogInFormProvider>
      </LookupWrapper>
    );
  }

  return (
    <LookupWrapper className="max-width">
      <ExternalLookupField {...voyado} />

      {(Object.values(voyado.error).some(Boolean) ||
        voyado.isCountryNotValid) && (
        <p>{t('Something went wrong. Please try again')}</p>
      )}
    </LookupWrapper>
  );
}

export default VoyadoLookup;
